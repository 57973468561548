'use strict';

var base = require('base/search/search');

function toggleFilterDrawer() {
    const toggle = document.getElementById('toggle-filter-drawer');
    const filterDrawer = document.getElementById('filter-drawer');
    if (toggle && filterDrawer) {
        toggle.addEventListener('click', () => {
            filterDrawer.classList.toggle('collapsed');
            toggle.classList.toggle('collapsed');
        });
    }
}

function observeStickyHeader() {
    const el = document.querySelector('.grid-header');

    if (el) {
        const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), { rootMargin: '-98.6px 0px 0px 0px', threshold: [1] }); // rootMargin set to offset the mobile navigation height

        observer.observe(el);
    }
}

let priceFilterList = $('.filter__refinementulprice--style .filter__pricerefinementvalue--style span[aria-hidden="true"]').last();
let maxValuePrice = 1000;
var searchURL = null;
if ($(priceFilterList).length > 0) {
    let priceFilterValues = $(priceFilterList).text().trim().split('-');

    if (priceFilterValues.length > 0) {
        maxValuePrice = parseInt(priceFilterValues[1].trim(), 10) + 1;
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);

    $(selector).empty().html($updates.html());
    if ($updates.hasClass('refinements')) {
        if (!searchURL) {
            searchURL = $updates.find('.refinement .content .values button').data('href');
        }
        history.replaceState('Search', document.title, decodeURIComponent(searchURL.replace('Ajax', '')));
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}
/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    toggleFilterDrawer();
}

/**
 * Update param in url
 *
 * @param {string} url - url to be updated
 * @param {string} paramName - param to be updated
 * @param {string} paramValue - value to be updated
 * @return {string} - search result url
 */
function replaceUrlParam(url, paramName, paramValue) {
    let tempParamValue = paramValue;
    let tempUrl = url;
    if (tempParamValue == null) {
        tempParamValue = '';
    }
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (tempUrl.search(pattern) >= 0) {
        return tempUrl.replace(pattern, '$1' + tempParamValue + '$2');
    }
    tempUrl = tempUrl.replace(/[?#]$/, '');
    return tempUrl + (tempUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + tempParamValue;
}

/**
 * Update Filter values after result appear
 *
 * @param {string} minV - current min value from the interval
 * @param {string} maxV - current max value from the interval
 * @param {string} maxPriceFilter - Max Price Filter value
 * @return {undefined}
 */
function parseResultsCustomPrice(minV, maxV, maxPriceFilter) {
    $('.range-min').val(minV);
    $('.range-max').val(maxV);
    $('.input-min').val(minV);
    $('.input-max').val(maxV);
    $('.range-min').attr('max', maxPriceFilter);
    $('.range-max').attr('max', maxPriceFilter);
    $('.price-interval .price-interval-max').empty().text('$' + maxPriceFilter);
    $('.price-placeholder .price-placeholder-min span').empty().text('$' + minV);
    $('.price-placeholder .price-placeholder-max span').empty().text('$' + maxV);
    $('.price-placeholder .price-placeholder-min').css('left', ((minV / maxPriceFilter) * 100) + '%');
    $('.price-placeholder .price-placeholder-max').css('right', (100 - ((maxV / maxPriceFilter) * 100)) + '%');
    $('.slider .progress').css('left', ((minV / maxPriceFilter) * 100) + '%');
    $('.slider .progress').css('right', (100 - ((maxV / maxPriceFilter) * 100)) + '%');
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Check if there are filters selected. If yes it will show the clear results button
 *
 */
function validateClearResults() {
    var urlParams = new URLSearchParams(location.href);
    if ($('.filter__button--style.filter__button--active, .filter__box--style.filter__box--active, .color-attribute .selected').length > 0 ||
        $('.range-min').val() !== $('.range-min').attr('min') ||
        (urlParams.get('pmin') && urlParams.get('pmin') !== $('.range-min').attr('min')) ||
        $('.range-max').val() !== $('.range-max').attr('max') ||
        (urlParams.get('pmax') && urlParams.get('pmax') !== $('.range-min').attr('max'))) {
        $('.refinement-bar .secondary-bar').removeClass('d-none');
    } else {
        $('.refinement-bar .secondary-bar').addClass('d-none');
    }
}

/**
 * Check if there is a sort rule in the URL params. If so, make sure sort dropdown has the correct sort option selected
 *
 */
function updateSortDropDown() {
    let params = new URLSearchParams(document.location.search);
    let sortRule = params.get('srule');
    if (sortRule !== null) {
        let sortDropDown = document.querySelector('.custom-select.sort-order');

        let currentlySelectedOption = sortDropDown.options[sortDropDown.selectedIndex];

        if (currentlySelectedOption.className !== sortRule) {
            currentlySelectedOption.removeAttribute('selected');
        }

        let selectedOption = sortDropDown.querySelector(`.${sortRule}`);
        if (selectedOption) {
            selectedOption.setAttribute('selected', '');
        }
    }
}

/**
 * Update the filer URL with sort rule selected parameter
 *
 * @param {string} url - filter url
 * @param {string} param - sort rule key
 * @param {string} value - sort rule value
 * @return {string} - search result url
 */
function updateUrlParameter(url, param, value) {
    var urlParts = url.split('?');
    var baseUrl = urlParts[0];
    var queryString = urlParts[1] || '';
    var newQueryString = '';
    var paramRegex = new RegExp('(^|&)' + param + '=([^&]*)');

    if (queryString.match(paramRegex)) {
        // If the parameter exists, remove it add selected sort parameter
        var updatedQueryString = queryString.replace(paramRegex, '').replace(/^&|&$/, '');
        newQueryString = updatedQueryString + '&' + param + '=' + value;
    } else {
        // If the parameter doesn't exist, add it
        newQueryString = queryString + '&' + param + '=' + value;
    }

    return baseUrl + '?' + newQueryString;
}

/**
 * Update the selected sort rule in dropdown
 * @param {string} parameter - selected sort option
 */
function selectSortOption(parameter) {
    var selectElement = $('#sortOrderSelect');

    // Find the option with the given data-id attribute value
    var newOption = selectElement.find('option[data-id="' + parameter + '"]');

    if (newOption.length) {
        // Deselect the currently selected option
        selectElement.find('option:selected').prop('selected', false);
        newOption.prop('selected', true);
        selectElement.trigger('change');
    }
}

base.applyFilter = function () {
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            let url = $(this).data('href');
            var selectedSortOption = $('.custom-select.sort-order').find('option:selected').data('id');
            // update the filer url with sort rule parameter
            url = updateUrlParameter(url, 'srule', selectedSortOption);
            let min;
            let max;
            if (!$(this).hasClass('reset')) {
                min = $('.range-min').val();
                max = $('.range-max').val();
                url = replaceUrlParam(url, 'pmin', min);
                url = replaceUrlParam(url, 'pmax', max);
            } else {
                min = $('.range-min').attr('min');
                max = $('.range-max').attr('max');
            }
            $.spinner().start();
            $(this).trigger('search:filter', e);
            searchURL = url;

            $.ajax({
                url: url,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: url
                },
                method: 'GET',
                success: function (response) {
                    parseResults(response);
                    parseResultsCustomPrice(min, max, maxValuePrice);
                    validateClearResults();
                    selectSortOption(selectedSortOption);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
};

base.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        var showMoreUrl = $(this).data('url');
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:showMore', e);
        $.ajax({
            url: showMoreUrl,
            data: {
                selectedUrl: showMoreUrl
            },
            method: 'GET',
            success: function (response) {
                $('.grid-footer').replaceWith(response);
                updateSortOptions(response);
                $('.resultsNo-pagesize').text($('.search-keywords').text().trim().replace(/[“”]/g, '') + ' ( ' + $('.product-tile').length + ' of ' + $('.search-result-count').text().trim().toLowerCase().replace(/[^\d,]/g, '') + ' ) ');
                var urlParams = new URLSearchParams(showMoreUrl);
                var start = parseInt(urlParams.get('start'), 10);
                var sz = parseInt(urlParams.get('sz'), 10);
                if (start && sz) {
                    var newSearchURL = replaceUrlParam(location.href, 'sz', sz + start);
                    newSearchURL = replaceUrlParam(newSearchURL, 'start', '0');
                    history.replaceState('Search', document.title, decodeURIComponent(newSearchURL));
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

base.sort = function () {
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        if (this.value.toLocaleLowerCase().replace(' ', '') !== 'sortby') {
            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: {
                    selectedUrl: this.value
                },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};

base.filter = function () {
    // Display refinements bar when Menu icon clicked
    $('.container').on('click', 'button.filter-results', function () {
        $('.refinement-bar, .modal-background').show();
        $('.refinement-bar').addClass('open');
        $('.refinement-bar').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
        $('.refinement-bar .close').focus();
    });
};

base.closeRefinements = function () {
    // Refinements close button
    $('.container').on('click', '.refinement-bar button.close, .modal-background, .refinement-bar .clearfix-close', function () {
        $('.refinement-bar').removeClass('open');
        $('.refinement-bar, .modal-background').delay(400).hide();
        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    });

    // This code would be placed in the method that opens the modal/menu:
    $('.container').on('keyup', function (event) {
        if (event.keyCode == 27) {
            $('.refinement-bar').removeClass('open');
            $('.refinement-bar, .modal-background').delay(400).hide(400);
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            $('.btn.filter-results').focus();
        }
    });
};

base.showProductTileAltImage = function () {
    $('body').on('mouseover', '.product-tile .image-container .tile-image--primary', function () {
        let image = $(this);
        if ($(image).attr('data-secondary-src') !== '' && $(image).attr('data-secondary-srcset') !== '') {
            $(image).attr('src', $(image).attr('data-secondary-src'));
            $(image).attr('srcset', $(image).attr('data-secondary-srcset'));
        }
    });
};

base.showProductTileFirstImage = function () {
    $('body').on('mouseout', '.product-tile .image-container .tile-image--primary', function () {
        let image = $(this);
        $(image).attr('src', $(image).attr('data-primary-src'));
        $(image).attr('srcset', $(image).attr('data-primary-srcset'));
    });
};

base.showProductTileSwatchImage = function () {
    $('body').on('click', '.product-tile .swatch--color__action', function (e) {
        e.preventDefault();
        var swatchTrigger = $(this);
        let params = $(this).data('params');
        if (params) {
            params = '&' + params;
        }
        $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('.image-container').spinner().start();
        $.get($(swatchTrigger).data('action') + params, function (data) {
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('src', data.images['hi-res'][0].mobile);
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('srcset', data.images['hi-res'][0].mobile + ' 300w, ' + data.images['hi-res'][0].mobile2x + ' 600w');
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-primary-src', data.images['hi-res'][0].mobile);
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-primary-srcset', data.images['hi-res'][0].mobile + ' 300w, ' + data.images['hi-res'][0].mobile2x + ' 600w');
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('alt', data.images['hi-res'][0].alt);
            if (data.images['hi-res'].length > 1) {
                $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-secondary-src', data.images['hi-res'][1].mobile);
                $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-secondary-srcset', data.images['hi-res'][1].mobile + ' 300w, ' + data.images['hi-res'][1].mobile2x + ' 600w');
                $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('alt', data.images['hi-res'][1].alt);
            } else {
                $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-secondary-srcset', '');
                $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('img.tile-image--primary').attr('data-secondary-src', '');
            }
            $(swatchTrigger).parent().siblings().children()
                .removeClass('selected');
            $(swatchTrigger).addClass('selected');
            $('.product[data-pid="' + $(swatchTrigger).data('pid') + '"]').find('.image-container').spinner().stop();
        });
    });
};

var productIdViewed = localStorage.getItem('productIdViewed');
if (productIdViewed && $(productIdViewed).length > 0) {
    localStorage.removeItem('productIdViewed');
    $('html, body').animate({
        scrollTop: $(productIdViewed).offset().top - 300
    }, 2000);
}
validateClearResults();
updateSortDropDown();
toggleFilterDrawer();
observeStickyHeader();

module.exports = base;
